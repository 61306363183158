<template>
	<div class="indexContent">
		<div class="scrollList">
			<div class="swiper-container gallery-top swiper-container-horizontal">
				<div class="swiper-wrapper">
					<div v-for="value in lbt" class="swiper-slide swiper-slide-next" style="width: 100%; height: 100%; margin-right: 10px;" v-bind:style="{backgroundImage: 'url(' + value.image + ')'}"></div>
				</div>
			</div>
			<div class="swiper-pagination" style="width: 93%;text-align: right;margin-right: 15px;bottom: 20px;position: relative;"></div>
		</div>
		<div style="padding: 10px 0 0 0; position: relative;">

			<img v-if="zhibo" src="//mediabook.oss-cn-shanghai.aliyuncs.com/moti/2019-11/1573044764197952.png" style="width: 100%;vertical-align: middle;" />

      <img v-if="!zhibo" src="//mediabook.oss-cn-shanghai.aliyuncs.com/moti/2019-11/1573045094277121.png" style="width: 100%;vertical-align: middle;" />



			<div v-if="zhibo" style="position: absolute; width: 100%; height: 100% ;top:0;">
				<div style="position: absolute; width: 37%; height: 100%; left: 0;" @click="GoList(1)"></div>
				<div style="position: absolute; width: 63%; height: 50%; right: 0; top: 0;" @click="GoList(3)"></div>
				<div style="position: absolute; width: 63%; height: 50%; right: 0; bottom: 0;" @click="GoList(2)"></div>
			</div>

      <div v-if="!zhibo" style="position: absolute; width: 100%; height: 100% ;top:0;">
      	<div style="position: absolute; width: 50%; height: 100%; left: 0;" @click="GoList(1)"></div>
      	<div style="position: absolute; width: 50%; height: 100%; right: 0; top: 0;" @click="GoList(2)"></div>

      </div>
		</div>



    <div style="padding:6px 0 0 6px; margin-top: 10px" v-show="zx.length>0">
    	<div class="hots flexleft"><span></span>咨询 <div @click="GoList(2)" style="position: absolute; right: 10px; font-size: 14px;">更多></div></div>
    </div>
    <div class="community">
    	<div class="itemList" v-for="(item,idx) of zx" v-if="idx==0" @click="ViewArticle(item)">
    		<div class="itemImg">
    			<img :src="item.image" />
    			<img :src="item.smlimage" v-if="item.smlimage" class="headimg" />
    		</div>
    		<div class="imgs" style="padding-top: 10px;" v-if="item.smlimage">
    			<img src="../../../public/static/images/zan.png" height="25" width="25">
    			<img src="../../../public/static/images/share.png" style="margin-left: 20px;" height="25" width="25" />
    		</div>
    		<div class="itemName">{{item.title}}</div>
          <div style="font-size: 12px; color: #888; padding-left: 14px;">{{item.cdate.replace("T",' ')}}</div>
    		<div class="itemVolume">{{item.summary}}</div>
    		<div class="imgs" v-if="!item.smlimage">
    			<img src="../../../public/static/images/zan.png" height="25" width="25">
    			<img src="../../../public/static/images/share.png" style="margin-left: 20px;" height="25" width="25" />
    		</div>
    	</div>
    </div>



		<div style="padding:16px 0 0 6px; position: relative;" v-show="wkt.length>0">
			<div class="hots flexleft"><span></span>微课堂 <div @click="GoList(1)" style="position: absolute; right: 10px; font-size: 14px;">更多></div></div>
		</div>
		<div class="community">
			<div class="itemList" v-for="(item,idx) of wkt" v-if="idx==0" @click="ViewArticle(item)">
				<div class="itemImg">
					<img :src="item.image" />
					<img :src="item.smlimage" v-if="item.smlimage" class="headimg" />
				</div>
				<div class="imgs" style="padding-top: 10px;" v-if="item.smlimage">
					<img src="../../../public/static/images/zan.png" height="25" width="25">
					<img src="../../../public/static/images/share.png" style="margin-left: 20px;" height="25" width="25" />
				</div>
				<div class="itemName">{{item.title}}</div>
        <div style="font-size: 12px; color: #888; padding-left: 14px;">{{item.cdate.replace("T",' ')}}</div>
				<div class="itemVolume">{{item.summary}}</div>
				<div class="imgs" v-if="!item.smlimage">
					<img src="../../../public/static/images/zan.png" height="25" width="25">
					<img src="../../../public/static/images/share.png" style="margin-left: 20px;" height="25" width="25" />
				</div>
			</div>


		</div>


		<div style="padding:6px 0 0 6px; margin-top: 10px;" v-show="zb.length>0&&zhibo">
			<div class="hots flexleft"><span></span>直播  <div @click="GoList(3)" style="position: absolute; right: 10px; font-size: 14px;">更多></div></div>
		</div>
		<div class="community"  v-show="zb.length>0&&zhibo">
			<div class="itemList"  v-for="(item,idx) of zb" v-if="idx==0" @click="ViewArticle(item)">
				<div class="itemImg">
					<img :src="item.image" />
					<img :src="item.smlimage" v-if="item.smlimage" class="headimg" />
				</div>
				<div class="imgs" style="padding-top: 10px;" v-if="item.smlimage">
					<img src="../../../public/static/images/zan.png" height="25" width="25">
					<img src="../../../public/static/images/share.png" style="margin-left: 20px;" height="25" width="25" />
				</div>
				<div class="itemName">{{item.title}}</div>
          <div style="font-size: 12px; color: #888; padding-left: 14px;">{{item.cdate.replace("T",' ')}}</div>
				<div class="itemVolume">{{item.summary}}</div>
				<div class="imgs" v-if="!item.smlimage">
					<img src="../../../public/static/images/zan.png" height="25" width="25">
					<img src="../../../public/static/images/share.png" style="margin-left: 20px;" height="25" width="25" />
				</div>
			</div>


		</div>



		<div style="height: 50px;"></div>

	</div>
</template>

<script>
	import Swiper from '../../../public/static/swiper.min.js'
	let galleryTop
	let galleryThumbs
	export default {
		data() {
			return {
				lbt: [],
				hotList: [], //热销列表
				wkt: [],
				zb: [],
				zx: [],
        zhibo:0
			}
		},
		created() {

			this.GetLunboList(); //轮播图
			this.GetNrList();
      this.getShowZhibo();

		},
		mounted() {
			document.title = "营养中心";
		},
		methods: {
			lunbo() {
				let that = this;
				galleryTop = new Swiper('.gallery-top', {
					// 如果需要分页器
					pagination: '.swiper-pagination',
					autoplay: 3000,
					speed: 300,
					spaceBetween: 10,
					grabCursor: true,
					initialSlide: 1,
					autoplayDisableOnInteraction: false,
					onClick: function(swiper) {
						for(var i = 0; i < that.lbt.length; i++) {
							if(i == swiper.activeIndex) {
								if(that.lbt[i].outhref != "" && that.lbt[i].outhref.indexOf("http") >= 0) {
									window.location.href = that.lbt[i].outhref
								} else {
									console.log("没有外链")
								}
							}
						}

					}
				})
				galleryThumbs = new Swiper('.swiper-pagination', {
					spaceBetween: 10,
					autoplay: 4000,
					initialSlide: 1,
					centeredSlides: true,
					slidesPerView: 'auto',
					touchRatio: 0.2,
					slideToClickedSlide: true,
					autoplayDisableOnInteraction: false,
					grabCursor: true
				})
				galleryTop.params.control = galleryThumbs
				galleryThumbs.params.control = galleryTop
			},
      getShowZhibo(){
        this.$store.dispatch("GET", {
        	url: "/api/config/?action=getzhibo"
        }).then(result => {
            if(result.data.data.rows.length>0){
              this.zhibo = parseInt(result.data.data.rows[0].configvalue)
            }

        })
      },
			//轮播图
			GetLunboList() {
				var self = this
				this.$store.dispatch("GET", {
					url: "/api/wx/?action=carousel"
				}).then(result => {
					self.lbt = result.data.data.rows.rows;
					this.$nextTick(() => {
						this.lunbo();
					})

				})
			},
			GetNrList() {
				var self = this
				this.$store.dispatch("GET", {
					url: "/api/article/?action=zhlist"
				}).then(result => {
					if(result.data.wkt){
						this.wkt = result.data.wkt
					}
					if(result.data.zb){
						this.zb = result.data.zb
					}
					if(result.data.zx){
						this.zx = result.data.zx
					}
				})
			},
			ViewArticle(item) {
				if(item.link.indexOf("http")>=0){
					window.location.href=item.link;
				}else{
					if(isNaN(item.link)){//商品id
						this.$router.push("/prodetail/" + item.link)
					}else{
						this.$router.push("/article/" + item.id)
					}
				}

			},
			GoList(id){
				this.$router.push("/subindex/" + id)
			}

		}
	}
</script>

<style @scoped>
	@import url("../../common/css/swiper.min.css");
	.gallery-top {
		height: 50vw;
		width: 100%;
	}

	.gallery-thumbs {
		height: 20%;
		box-sizing: border-box;
		background: rgba(0, 0, 0, 0.4);
		opacity: 0.3;
		cursor: pointer;
	}

	.gallery-thumbs .swiper-slide {
		width: 30%;
		opacity: 0.3;
	}

	.gallery-thumbs .swiper-slide-active {
		opacity: 1;
	}

	.swiper-pagination-bullet {
		width: 8px;
		height: 8px;
		display: inline-block;
		border-radius: 100%;
		border: 1px solid #656565;
		margin-left: 10px;
	}

	.swiper-slide {
		background-size: 100% 100%;
		-webkit-background-size: 100% 100%;
	}

	.header {
		width: 100%;
		height: 54px;
	}

	.scrollList {
		width: 100%;
		height: 50vw;
	}

	.proType {
		width: 100%;
		height: 47.5vw;
		display: flex;
		margin-top: 10px;
	}

	.proTypeLeft {
		margin-left: 10px;
		width: 33%;
		height: 166px;
		background: #FFFFFF;
		border-radius: 10px;
	}

	.proTypeRight {
		flex: 1;
		margin-left: 10px;
		margin-right: 10px;
	}

	.proList {
		height: 500px;
		/*border: 1px solid red;*/
		margin-bottom: 60px;
	}

	.hotTitle {
		height: 36px;
		line-height: 36px;
		display: flex;
		padding-left: 10px;
		padding-right: 10px;
	}

	.hots {
		flex: 1;
		font-size: 16px;
		color: #5586d2;
	}

	.hots span {
		background: #5586d2;
		width: 6px;
		height: 26px;
		display: inline-block;
		margin-right: 5px;
	}

	.more {
		flex: 1;
		text-align: right;
		font-size: 12px;
		color: #a3a3a3;
	}

	.hotList {
		margin-top: 10px;
		padding-left: 2.6%;
	}

	.hotlist_left {
		float: left;
		border: 1px solid #bde2ff;
		width: 47.5%;
		margin-right: 1.5%;
		height: 57vw;
		background: #FFFFFF;
		margin-bottom: 10px;
		position: relative;
	}

	.hotimg {
		text-align: center;
		padding-top: 20px;
	}

	.hotimg img {
		width: 40vw;
		height: 40vw;
	}

	.hotlist_middle {
		float: left;
		width: 2.5%;
	}

	.proname {
		text-align: center;
		font-size: 13px;
		color: #7b7b7b;
		padding: 0px 5%;
		height: 20px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 90%;
		line-height: 20px;
	}

	.clear {
		clear: both;
	}

	.community {
		height: 100%;
		width: 100%;
	}

	.itemList {
		background: #FFFFFF;
		border-radius: 5px;
		width: 96%;
		margin: 0 auto;
		margin-top: 20px;
		padding-bottom: 10px;
	}

	.itemImg {
		width: 100%;
		height: 54vw;
		position: relative;
	}

	.headimg {
		position: absolute;
		width: 20vw;
		height: 20vw;
		left: 20px;
		bottom: -10vw;
		border-radius: 50%;
	}

	.itemImg img:nth-child(1) {
		height: 100%;
		width: 100%;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}

	.itemName {
		font-size: 16px;
		color: #474747;
		padding: 12px;
	}

	.itemVolume {
		font-size: 12px;
		color: #9a9a9a;
		padding: 5px 12px;
	}

	.imgs {
		padding: 5px 12px 15px 12px;
		text-align: right;
	}
</style>
